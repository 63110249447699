import { Button } from '@finn/design-system';
import { Link } from '@finn/design-system/icons/link';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import React from 'react';
import { useIntl } from 'react-intl';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import { LoginScreen, useLoginStore } from './store/useLoginStore';

const method = 'MagicLink';

export const MagicLinkButton: React.FC = () => {
  const i18n = useIntl();
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const track = useTrackingStore((state) => state.track);

  const onButtonClick = () => {
    track(TrackingEventName.LOGIN_METHOD_CLICKED, {
      additionalProps: {
        loginMethod: method,
      },
    });
    setLoginScreen(LoginScreen.magicLinkForm);
  };
  const loginText = i18n.formatMessage({
    id: `userAccount.button.magicLink`,
  });

  return (
    <Button
      onClick={onButtonClick}
      variant="outline"
      className="border-pewter hover:bg-snow w-full hover:fill-black hover:text-black"
    >
      <Link className="mr-2" />
      <span className="body-14-semibold">{loginText}</span>
    </Button>
  );
};
