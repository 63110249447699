import DayJsUtils from '@date-io/dayjs';
import { Input } from '@finn/design-system';
import { DateInfoContext } from '@finn/ui-components';
import { getCorrectLocale } from '@finn/ui-utils';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import deLocale from 'dayjs/locale/de';
import enLocale from 'dayjs/locale/en';
import { FormikProps } from 'formik';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

type props = {
  formik?: FormikProps<any>;
  disableInput?: boolean;
  variant?: WrapperVariant;
  dateFormatVariant?: 'short' | 'long';
};

const TextFieldComponent = ({ InputProps, inputRef, ...props }) => {
  return (
    <Input
      type="numeric"
      ref={inputRef}
      onClick={InputProps.onClick}
      {...props}
      readOnly={false}
    />
  );
};

const CustomDatePicker: React.FunctionComponent<
  KeyboardDatePickerProps & props
> = ({
  formik,
  disableInput,
  variant = 'inline',
  onOpen,
  dateFormatVariant,
  ...rest
}) => {
  const i18n = useIntl();

  const { dateFormat, dateFormatLong, dateMask } = useContext(DateInfoContext);
  const selectedDateFormat =
    dateFormatVariant === 'long' ? dateFormatLong : dateFormat;
  const [isOpen, setIsOpen] = useState(false);

  const locale = getCorrectLocale(i18n.locale);

  const id = rest.name || '';

  const handleOpen = () => {
    if (!rest.readOnly) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    formik?.setFieldTouched(id);
    setIsOpen(false);
  };

  return (
    <MuiPickersUtilsProvider
      utils={DayJsUtils}
      locale={locale.lang === 'en' ? enLocale : deLocale}
    >
      <KeyboardDatePicker
        allowKeyboardControl
        disableToolbar
        fullWidth
        open={isOpen}
        InputProps={{
          onClick: () => {
            onOpen?.();
            handleOpen();
          },
          inputProps: {
            readOnly: disableInput,
            inputMode: 'numeric',
          },
        }}
        onClose={handleClose}
        variant={variant}
        format={selectedDateFormat}
        mask={dateMask}
        invalidDateMessage={null}
        minDateMessage={null}
        maxDateMessage={null}
        TextFieldComponent={TextFieldComponent}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
export default CustomDatePicker;
